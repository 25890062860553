import { Component, Vue, Prop } from "vue-property-decorator";
import { PageName } from "@/types/page";
import { telegramSupportBotLink } from "@config/social";

@Component
export default class LockedPageBanner extends Vue {
  @Prop({ type: String, required: true }) private readonly pageName!: PageName;

  private get iconSrc() {
    if (this.pageName === PageName.TEAM) {
      return require("./img/team/locked.png");
    }

    if (this.pageName === PageName.REFERRAL_PROGRAM) {
      return require("./img/referral-program/locked.png");
    }
  }

  private get iconMaxWidth() {
    if (this.pageName === PageName.REFERRAL_PROGRAM) {
      return this.$vuetify.breakpoint.mobile ? 240 : 340;
    }

    return this.$vuetify.breakpoint.mobile ? 180 : 228;
  }

  private get bannerText() {
    if (this.pageName === PageName.TEAM) {
      return this.$vuetify.lang.t("$vuetify.locked_page.team.text");
    }

    if (this.pageName === PageName.REFERRAL_PROGRAM) {
      return this.$vuetify.lang.t("$vuetify.locked_page.referral_program.text");
    }

    return null;
  }

  private get telegramSupportBotLink() {
    return telegramSupportBotLink;
  }

  private get telegramSupportBotButtonHeight() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 44;
    }

    return 38;
  }
}
